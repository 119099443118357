import React, { Component } from "react";
import { ButtonBox, Button } from "./ButtonStyles";
import { getAudioUrl } from "../Utilities/getAudioUrl";

class MakeCallBtn extends Component {
  makeCall = (event) => {
    (async () => {
      const timeOut = new Promise((resolve, reject) => {
        setTimeout(resolve, 10000, "request timeout");
      });

      const audioUrlPromise = new Promise((resolve, reject) => {
        const audioUrl = getAudioUrl(
          this.props.backendUrl,
          this.props.language,
          this.props.text
        );
        resolve(audioUrl);
      });

      Promise.race([audioUrlPromise, timeOut]).then((value) => {
        if (value === "request timeout") {
          console.error("Error no audio url");
          alert(
            "Network error. Please check internet connection and refresh page to restart demo."
          );
        } else {
          try {
            const audio = new Audio(this.props.backendUrl + value);
            audio.play();
            audio.onplay = () => {
              this.props.playTtsAnimation();
              this.props.setHideMakeCallBtn(true);
              this.props.setHideMultipleChoice1(false);
              this.props.addSentence(`Robot: ${this.props.text}`);
            };
          } catch (error) {
            console.error("Error playing audio: " + error);
          }
        }
      });
    })();
  };

  render() {
    return (
      <ButtonBox>
        <Button onClick={this.makeCall}>Call customer</Button>
      </ButtonBox>
    );
  }
}

export default MakeCallBtn;
