import React, { Component } from "react";
import { MultipleChoices, Choice } from "./ChoicesStyles";
import RecordSpeech from "./RecordSpeech";

class MultipleChoices5 extends Component {
  render() {
    if (this.props.language === "Eng") {
      if (this.props.scenario === "Verify credit account information: ") {
        return (
          <MultipleChoices>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice5}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
      if (this.props.scenario === "Play terms and conditions: ") {
        return (
          <MultipleChoices>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice5}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
    } else if (this.props.language === "Man") {
      if (this.props.scenario === "Verify credit account information: ") {
        return (
          <MultipleChoices>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice5}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
      if (this.props.scenario === "Play terms and conditions: ") {
        return (
          <MultipleChoices>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice5}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
    }
  }
}

export default MultipleChoices5;
