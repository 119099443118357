import React, { Component } from "react";
import { MultipleChoices, Choice } from "./ChoicesStyles";
import RecordSpeech from "./RecordSpeech";
import OkEng from "./OkEng";
import OkMan from "./OkMan";
import FourDigitsEng from "./FourDigitsEng";
import FourDigitsMan from "./FourDigitsMan";

class MultipleChoices4 extends Component {
  render() {
    if (this.props.language === "Eng") {
      if (this.props.scenario === "Verify credit account information: ") {
        return (
          <MultipleChoices>
            <Choice>
              <FourDigitsEng
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="5432"
              />
            </Choice>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
      if (this.props.scenario === "Remind credit card payment: ") {
        return (
          <MultipleChoices>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
      if (this.props.scenario === "Play terms and conditions: ") {
        return (
          <MultipleChoices>
            <Choice>
              <OkEng
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="Okay."
              />
            </Choice>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
    } else if (this.props.language === "Man") {
      if (this.props.scenario === "Verify credit account information: ") {
        return (
          <MultipleChoices>
            <Choice>
              <FourDigitsMan
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="5432"
              />
            </Choice>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
      if (this.props.scenario === "Remind credit card payment: ") {
        return (
          <MultipleChoices>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
      if (this.props.scenario === "Play terms and conditions: ") {
        return (
          <MultipleChoices>
            <Choice>
              <OkMan
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="好的。"
              />
            </Choice>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice4}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
    }
  }
}

export default MultipleChoices4;
