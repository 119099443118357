import axios from "axios";

const setQuestion1 = async (
  backendUrl,
  language,
  scenario,
  setQuestion,
  setConnection,
  setQuoteLeft,
  setQuoteRight,
  addSentence
) => {
  let text;
  if (language === "Eng") {
    if (scenario === "Verify credit account information: ") {
      text =
        '"Hello Mr. Stark. You applied for a credit card at Standard Chartered Bank. To finish the application, we need to verify your personal information. You need to answer two questions, which takes about three minutes. Do you have time to finish this verification now?"';
    }
    if (scenario === "Remind credit card payment: ") {
      text =
        '"Hello Mr. Rogers. I\'m calling to remind you that your credit card payment for account number 5432 is due today. The total amount due is $230. Please remember to make your payment on time."';
    }
    if (scenario === "Play terms and conditions: ") {
      text =
        '"Hello Mr. Parker. You applied for a credit card at Standard Chartered Bank. To finish the application, you need to consent to a few important terms and conditions, which takes about five minutes. Do you have time to finish the consent process now?"';
    }
  } else if (language === "Man") {
    if (scenario === "Verify credit account information: ") {
      text =
        '"张先生您好。您在渣打银行申请办理了一张信用卡。按照开卡申请流程，现在需要与您进行一下信息验证，您需要回答两个问题，大约需要三分钟时间。请问您现在方便进行这个验证操作吗？"';
    }
    if (scenario === "Remind credit card payment: ") {
      text =
        '"王先生您好。您尾号为五四三二的信用卡有一笔还款今天就要到期了，金额是230美元，请注意按时还款。"';
    }
    if (scenario === "Play terms and conditions: ") {
      text =
        '"李先生您好。您在渣打银行申请办理了一张信用卡。按照开卡申请流程，现在需要您同意几项重要的条款，大约需要五分钟时间。请问您现在方便吗？"';
    }
  }

  const audioUrlPromise = new Promise((resolve, reject) => {
    const res = axios
      .post(`${backendUrl}/getAudioUrl`, {
        timestamp: Date.now(),
        language: language,
        text: text,
      })
      .catch((err) => {
        console.error(err);
      });
    resolve(res);
  });

  const timeOut = new Promise((resolve, reject) => {
    setTimeout(resolve, 100000, "request timeout");
  });

  Promise.race([audioUrlPromise, timeOut]).then((value) => {
    if (value === "request timeout") {
      console.error("Error no audio url");
      alert(
        "Network error. Please check internet connection and refresh page to restart demo."
      );
    } else {
      try {
        const audio = new Audio(backendUrl + value.data);
        audio.play();
        audio.onplay = () => {
          setQuestion(1);
          setConnection("ttsConnection");
          setQuoteLeft(text);
          setQuoteRight(null);
          addSentence(`Robot: ${text}`);
        };
      } catch (error) {
        console.log("Error playing audio: " + error);
      }
    }
  });
};

export { setQuestion1 };
