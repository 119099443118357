import styled from "styled-components";

const MultipleChoices = styled.ul`
  list-style: none;
  padding-left: 0px;
  display: inline-block;
`;

const Choice = styled.li`
  position: relative;
  @media (min-width: 1300px) {
    left: 870px;
  }
  @media (min-width: 1280px) and (max-width: 1299.98px) {
    left: 840px;
  }
  @media (min-width: 1210px) and (max-width: 1279.98px) {
    left: 800px;
  }
  @media (min-width: 1110px) and (max-width: 1209.98px) {
    left: 700px;
  }
  @media (min-width: 1010px) and (max-width: 1109.98px) {
    left: 600px;
  }
  @media (min-width: 904px) and (max-width: 1009.98px) {
    left: 500px;
  }
`;

export { MultipleChoices, Choice };
