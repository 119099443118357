import React, { Component } from "react";
import BIBig from "../BrandIntro/BIBig";
import DemoBig from "../Demo/DemoBig";

class BigScreen extends Component {
  render() {
    return (
      <div>
        <h2>iConversation</h2>
        <BIBig />
        <DemoBig
          backendUrl={this.props.backendUrl}
          luisEngUrl={this.props.luisEngUrl}
          luisManUrl={this.props.luisManUrl}
          luisKey={this.props.luisKey}
          sttUrl={this.props.sttUrl}
          sttKey={this.props.sttKey}
        />
      </div>
    );
  }
}

export default BigScreen;
