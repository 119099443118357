import React, { Component } from "react";
import { MultipleChoices, Choice } from "./ChoicesStyles";
import YesEng from "./YesEng";
import NoEng from "./NoEng";
import RecordSpeech from "./RecordSpeech";
import YesMan from "./YesMan";
import NoMan from "./NoMan";
import OkEng from "./OkEng";
import OkMan from "./OkMan";

class MultipleChoices2 extends Component {
  render() {
    if (this.props.language === "Eng") {
      if (this.props.scenario === "Remind credit card payment: ") {
        return (
          <MultipleChoices>
            <Choice>
              <OkEng
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="Okay."
              />
            </Choice>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      } else {
        return (
          <MultipleChoices>
            <Choice>
              <YesEng
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="Yes."
              />
            </Choice>
            <Choice>
              <NoEng
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="No."
              />
            </Choice>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisEngUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
    }
    if (this.props.language === "Man") {
      if (this.props.scenario === "Remind credit card payment: ") {
        return (
          <MultipleChoices>
            <Choice>
              <OkMan
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="好的。"
              />
            </Choice>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      } else {
        return (
          <MultipleChoices>
            <Choice>
              <YesMan
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="方便的。"
              />
            </Choice>
            <Choice>
              <NoMan
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
                text="不方便。"
              />
            </Choice>
            <Choice>
              <RecordSpeech
                backendUrl={this.props.backendUrl}
                luisUrl={this.props.luisManUrl}
                luisKey={this.props.luisKey}
                question={this.props.question}
                language={this.props.language}
                scenario={this.props.scenario}
                sttUrl={this.props.sttUrl}
                sttKey={this.props.sttKey}
                setQuestion={this.props.setQuestion}
                setLuisRes={this.props.setLuisRes}
                setTopIntent={this.props.setTopIntent}
                setQuoteLeft={this.props.setQuoteLeft}
                setQuoteRight={this.props.setQuoteRight}
                setHideMultipleChoice={this.props.setHideMultipleChoice2}
                setConnection={this.props.setConnection}
                addSentence={this.props.addSentence}
              />
            </Choice>
          </MultipleChoices>
        );
      }
    }
  }
}

export default MultipleChoices2;
