import styled from "styled-components";

const ButtonBox = styled.div`
  width: 300px;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  display: inline-block;
`;

const Button = styled.button`
  border: 2px solid #dedede;
  padding: 6px;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;

  &:hover {
    font-weight: bold;
  }

  &:focus {
    font-weight: bold;
    background-color: #cce8ff;
  }
`;

export { ButtonBox, Button };
