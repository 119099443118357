import React, { Component } from "react";
import { ButtonBox, Button } from "../Buttons/ButtonStyles";
import { getLuisRes } from "../Utilities/getLuisRes";
import { setQuestion1 } from "../Utilities/setQuestion1";
import { setQuestion2 } from "../Utilities/setQuestion2";

class YesEng extends Component {
  setQuote = async (event) => {
    this.props.setQuoteRight(this.props.text);
    this.props.setHideMultipleChoice(true);
    this.props.setConnection("srConnection");
    this.props.addSentence(`Customer: "${this.props.text}"`);
    const luisRes = await getLuisRes(
      this.props.luisUrl,
      this.props.luisKey,
      this.props.text,
      this.props.setLuisRes,
      this.props.setTopIntent
    );
    const qNum = this.props.question;
    if (luisRes.topIntent === "Utilities.Confirm" && qNum === 0) {
      setQuestion1(
        this.props.backendUrl,
        this.props.language,
        this.props.scenario,
        this.props.setQuestion,
        this.props.setConnection,
        this.props.setQuoteLeft,
        this.props.setQuoteRight,
        this.props.addSentence
      );
    } else if (luisRes.topIntent === "Utilities.Confirm" && qNum === 1) {
      setQuestion2(
        this.props.backendUrl,
        this.props.language,
        this.props.scenario,
        this.props.setQuestion,
        this.props.setConnection,
        this.props.setQuoteLeft,
        this.props.setQuoteRight,
        this.props.addSentence
      );
    }
  };

  render() {
    return (
      <ButtonBox>
        <Button onClick={this.setQuote}>"{this.props.text}"</Button>
      </ButtonBox>
    );
  }
}

export default YesEng;
