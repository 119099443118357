import axios from "axios";

const setQuestion2 = async (
  backendUrl,
  language,
  scenario,
  setQuestion,
  setConnection,
  setQuoteLeft,
  setQuoteRight,
  addSentence
) => {
  let text;
  if (language === "Eng") {
    if (scenario === "Verify credit account information: ") {
      text =
        '"What is the last four digits of your identification card number?"';
    }
    if (scenario === "Remind credit card payment: ") {
      text = '"Do you have any other questions?"';
    }
    if (scenario === "Play terms and conditions: ") {
      text =
        '"Thank you for opening a credit card account with us. By opening an account, you agree to the terms and conditions listed in Credit Account User Agreement. Most importantly, you must pay us at least the minimum payment amount by the payment due date. We may charge you late fee if we do not receive your payment as instructed on your statement by the payment due date. Late payments, missed payments, or other defaults on your account may be reflected in your credit report. If your account has a membership fee, we may charge the first membership fee either on the day you activate your card or on the day when you use your account, whichever occurs first."';
    }
  } else if (language === "Man") {
    if (scenario === "Verify credit account information: ") {
      text = '"请问您身份证号码的后4位是什么？"';
    }
    if (scenario === "Remind credit card payment: ") {
      text = '"请问您还有别的问题吗？"';
    }
    if (scenario === "Play terms and conditions: ") {
      text =
        '"感谢您在我行开设信用卡帐户。开设帐户即表示您同意“信用帐户用户协议”中列出的条款和条件。最重要的是，您必须在付款到期日之前向我们至少支付最低付款金额。如果我们在付款到期日之前未按照您对帐单上的指示收到付款，我们可能会向您收取滞纳金。您的帐户中的逾期付款，未付款项或其他默认付款可能会反映在您的信用报告中。如果您的帐户有年费，我们可能会在您激活卡之日或您使用帐户之日收取第一笔年费，以先到者为准。"';
    }
  }

  const res = await axios
    .post(
      `${backendUrl}/getAudioUrl`,
      {
        timestamp: Date.now(),
        language: language,
        text: text,
      },
      {
        timeout: 200000,
      }
    )
    .catch((err) => {
      console.error(err);
      alert(
        "Network error. Please check internet connection and refresh page to restart demo."
      );
    });

  if (res != null && res.data != null) {
    try {
      const audio = new Audio(backendUrl + res.data);
      audio.play();
      audio.onplay = () => {
        setQuestion(2);
        setConnection("ttsConnection");
        setQuoteLeft(text);
        setQuoteRight(null);
        addSentence(`Robot: ${text}`);
      };
    } catch (error) {
      console.log("Error playing audio: " + error);
    }
  }
};

export { setQuestion2 };
