import React from "react";
import styled from "styled-components";
import MediaQuery from "react-responsive";

const SrConnection = styled.h1`
  display: inline-block;
  position: relative;
  margin: 0px;
  top: -100px;
`;

const srConnection = (
  <span>
    <MediaQuery minWidth={1300}>
      <SrConnection className="srConnection">
        ......................Speech Recognition......................
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1290} maxWidth={1299.98}>
      <SrConnection className="srConnection">
        ....................Speech Recognition....................
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1280} maxWidth={1289.98}>
      <SrConnection className="srConnection">
        ...................Speech Recognition...................
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1260} maxWidth={1279.98}>
      <SrConnection className="srConnection">
        ..................Speech Recognition..................
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1240} maxWidth={1259.98}>
      <SrConnection className="srConnection">
        .................Speech Recognition.................
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1220} maxWidth={1239.98}>
      <SrConnection className="srConnection">
        ................Speech Recognition................
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1200} maxWidth={1219.98}>
      <SrConnection className="srConnection">
        ...............Speech Recognition...............
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1185} maxWidth={1199.98}>
      <SrConnection className="srConnection">
        ..............Speech Recognition..............
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1170} maxWidth={1184.98}>
      <SrConnection className="srConnection">
        .............Speech Recognition.............
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1150} maxWidth={1169.98}>
      <SrConnection className="srConnection">
        ............Speech Recognition............
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1135} maxWidth={1149.98}>
      <SrConnection className="srConnection">
        ...........Speech Recognition...........
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1115} maxWidth={1134.98}>
      <SrConnection className="srConnection">
        ..........Speech Recognition..........
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1100} maxWidth={1114.98}>
      <SrConnection className="srConnection">
        .........Speech Recognition.........
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1080} maxWidth={1099.98}>
      <SrConnection className="srConnection">
        ........Speech Recognition........
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1065} maxWidth={1079.98}>
      <SrConnection className="srConnection">
        .......Speech Recognition.......
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1045} maxWidth={1064.98}>
      <SrConnection className="srConnection">
        ......Speech Recognition......
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1030} maxWidth={1044.98}>
      <SrConnection className="srConnection">
        .....Speech Recognition.....
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={1010} maxWidth={1029.98}>
      <SrConnection className="srConnection">
        ....Speech Recognition....
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={995} maxWidth={1009.98}>
      <SrConnection className="srConnection">
        ...Speech Recognition...
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={975} maxWidth={994.98}>
      <SrConnection className="srConnection">
        ..Speech Recognition..
      </SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={960} maxWidth={974.98}>
      <SrConnection className="srConnection">.Speech Recognition.</SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={940} maxWidth={959.98}>
      <SrConnection className="srConnection">Speech Recognition</SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={925} maxWidth={939.98}>
      <SrConnection className="srConnection">Recognize Speech</SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={910} maxWidth={924.98}>
      <SrConnection className="srConnection">Recognize Speech</SrConnection>
    </MediaQuery>
    <MediaQuery minWidth={904} maxWidth={909.98}>
      <SrConnection className="srConnection">Recognize Speech</SrConnection>
    </MediaQuery>
  </span>
);

export { srConnection };
