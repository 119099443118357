import React, { Component } from "react";
import { ButtonBox, Button } from "../Buttons/ButtonStyles";
import { speechToText } from "../Utilities/speechToText";
import { getLuisRes } from "../Utilities/getLuisRes";
import { setQuestion1 } from "../Utilities/setQuestion1";
import { setHangup1 } from "../Utilities/setHangup1";
import { setQuestion2 } from "../Utilities/setQuestion2";
import { setHangup2 } from "../Utilities/setHangup2";
import { setHangup3 } from "../Utilities/setHangup3";
import { setQuestion3 } from "../Utilities/setQuestion3";
import { setHangup4 } from "../Utilities/setHangup4";

class RecordSpeech extends Component {
  recognizeSpeech = async (event) => {
    speechToText(this.props.language, this.props.sttUrl, this.props.sttKey)
      .then(async (result) => {
        this.props.setQuoteRight(result);
        this.props.setHideMultipleChoice(true);
        this.props.setConnection("srConnection");
        this.props.addSentence(`Customer: "${result}"`);
        const luisRes = await getLuisRes(
          this.props.luisUrl,
          this.props.luisKey,
          result,
          this.props.setLuisRes,
          this.props.setTopIntent
        );
        const qNum = this.props.question;
        if (luisRes.topIntent === "Utilities.Confirm" && qNum === 0) {
          setQuestion1(
            this.props.backendUrl,
            this.props.language,
            this.props.scenario,
            this.props.setQuestion,
            this.props.setConnection,
            this.props.setQuoteLeft,
            this.props.setQuoteRight,
            this.props.addSentence
          );
        } else if (luisRes.topIntent === "Utilities.Reject" && qNum === 0) {
          setHangup1(
            this.props.backendUrl,
            this.props.language,
            this.props.setConnection,
            this.props.setQuoteLeft,
            this.props.addSentence
          );
        } else if (luisRes.topIntent === "Utilities.Confirm" && qNum === 1) {
          setQuestion2(
            this.props.backendUrl,
            this.props.language,
            this.props.scenario,
            this.props.setQuestion,
            this.props.setConnection,
            this.props.setQuoteLeft,
            this.props.setQuoteRight,
            this.props.addSentence
          );
        } else if (luisRes.topIntent === "Utilities.Reject" && qNum === 1) {
          setHangup2(
            this.props.backendUrl,
            this.props.language,
            this.props.setQuestion,
            this.props.setConnection,
            this.props.setQuoteLeft,
            this.props.setQuoteRight,
            this.props.addSentence
          );
        } else if (luisRes.topIntent === "ConfirmTime" && qNum === 1.1) {
          setHangup3(
            this.props.backendUrl,
            this.props.language,
            this.props.setConnection,
            this.props.setQuoteLeft,
            this.props.addSentence,
            luisRes.entities[0]
          );
        } else if (qNum === 2) {
          setQuestion3(
            this.props.backendUrl,
            this.props.language,
            this.props.scenario,
            this.props.setQuestion,
            this.props.setConnection,
            this.props.setQuoteLeft,
            this.props.setQuoteRight,
            this.props.addSentence
          );
        } else if (qNum === 3) {
          setHangup4(
            this.props.backendUrl,
            this.props.language,
            this.props.setConnection,
            this.props.setQuoteLeft,
            this.props.addSentence
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    return (
      <ButtonBox>
        <Button onClick={this.recognizeSpeech}>
          Record with speech recognition
        </Button>
      </ButtonBox>
    );
  }
}

export default RecordSpeech;
