import React, { Component } from "react";
import { ButtonBox, Button } from "./ButtonStyles";

class StartDemoEngBtn extends Component {
  startDemo = (event) => {
    // event.preventDefault();
    this.props.setDemoStage(1);
    this.props.setLanguage("Eng");
  };

  render() {
    return (
      <ButtonBox>
        <Button onClick={this.startDemo}>Start demo (English)</Button>
      </ButtonBox>
    );
  }
}

export default StartDemoEngBtn;
