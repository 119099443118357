import React, { Component } from "react";
import Scenario1Btn from "../Buttons/Scenario1Btn";
import Scenario2Btn from "../Buttons/Scenario2Btn";
import Scenario3Btn from "../Buttons/Scenario3Btn";

class Stage1Big extends Component {
  render() {
    return (
      <div>
        <h2>Pick a calling scenario: </h2>
        <Scenario1Btn
          language={this.props.language}
          setDemoStage={this.props.setDemoStage}
          setDemoScenario={this.props.setDemoScenario}
        />
        <Scenario2Btn
          language={this.props.language}
          setDemoStage={this.props.setDemoStage}
          setDemoScenario={this.props.setDemoScenario}
        />
        <Scenario3Btn
          language={this.props.language}
          setDemoStage={this.props.setDemoStage}
          setDemoScenario={this.props.setDemoScenario}
        />
      </div>
    );
  }
}

export default Stage1Big;
