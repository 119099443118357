import React, { Component } from "react";
import { ButtonBox, Button } from "../Buttons/ButtonStyles";
import { getLuisRes } from "../Utilities/getLuisRes";
import { setQuestion3 } from "../Utilities/setQuestion3";

class FourDigitsMan extends Component {
  setQuote = async (event) => {
    this.props.setQuoteRight(this.props.text);
    this.props.setHideMultipleChoice(true);
    this.props.setConnection("srConnection");
    this.props.addSentence(`Customer: "${this.props.text}"`);
    const luisRes = await getLuisRes(
      this.props.luisUrl,
      this.props.luisKey,
      this.props.text,
      this.props.setLuisRes,
      this.props.setTopIntent
    );
    const qNum = this.props.question;
    if (qNum === 2) {
      luisRes.entities.forEach((entity, index) => {
        if (entity.length === 4) {
          setQuestion3(
            this.props.backendUrl,
            this.props.language,
            this.props.scenario,
            this.props.setQuestion,
            this.props.setConnection,
            this.props.setQuoteLeft,
            this.props.setQuoteRight,
            this.props.addSentence
          );
        }
      });
    }
  };

  render() {
    return (
      <ButtonBox>
        <Button onClick={this.setQuote}>"{this.props.text}"</Button>
      </ButtonBox>
    );
  }
}

export default FourDigitsMan;
