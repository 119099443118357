import React, { Component } from "react";
import { ButtonBox, Button } from "./ButtonStyles";

class Scenario3Btn extends Component {
  startStage2 = (event) => {
    this.props.setDemoStage(2);
    this.props.setDemoScenario("Play terms and conditions: ");
  };

  render() {
    return (
      <ButtonBox>
        <Button onClick={this.startStage2}>Play terms and conditions</Button>
      </ButtonBox>
    );
  }
}

export default Scenario3Btn;
