import axios from "axios";

const getLuisRes = async (
  luisUrl,
  luisKey,
  query,
  setLuisRes,
  setTopIntent
) => {
  const res = await axios
    .get(luisUrl, {
      params: {
        verbose: false,
        timezoneOffset: 480,
        "subscription-key": luisKey,
        q: query,
      },
    })
    .catch((err) => {
      console.error(err);
    });

  if (res != null) {
    try {
      const topIntent = res.data.topScoringIntent.intent;
      const entities = res.data.entities;
      let entityRes = [];
      entities.forEach((element) => {
        entityRes.push(element.entity);
      });
      let string = `
  {
    "query": "${res.data.query}",
    "topScoringIntent": {
      "intent": "${topIntent}",
      "score": ${res.data.topScoringIntent.score},
    },
    "entities:" [${entityRes}]
  }`;
      await setLuisRes(string);
      await setTopIntent(topIntent);
      return { topIntent: topIntent, entities: entityRes };
    } catch (error) {
      console.error(error);
    }
  } else {
    console.error("Error with luis get request");
  }
};

export { getLuisRes };
