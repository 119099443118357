import axios from "axios";

const setHangup2 = async (
  backendUrl,
  language,
  setQuestion,
  setConnection,
  setQuoteLeft,
  setQuoteRight,
  addSentence
) => {
  let text;
  if (language === "Eng") {
    text = '"Sure. I\'ll call you back later. What time are you available?"';
  } else if (language === "Man") {
    text = '"好的。我过一阵子再打给您。请问您什么时候有空？"';
  }

  const res = await axios
    .post(
      `${backendUrl}/getAudioUrl`,
      {
        timestamp: Date.now(),
        language: language,
        text: text,
      },
      {
        timeout: 100000,
      }
    )
    .catch((err) => {
      console.error(err);
      alert(
        "Network error. Please check internet connection and refresh page to restart demo."
      );
    });

  if (res != null && res.data != null) {
    try {
      const audio = new Audio(backendUrl + res.data);
      audio.play();
      audio.onplay = () => {
        setQuestion(1.1);
        setConnection("ttsConnection");
        setQuoteLeft(text);
        setQuoteRight(null);
        addSentence(`Robot: ${text}`);
      };
    } catch (error) {
      console.log("Error playing audio: " + error);
    }
  }
};

export { setHangup2 };
