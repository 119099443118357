import React, { Component } from "react";

const titleStyle = {
  textAlign: "center",
};

class BIBig extends Component {
  render() {
    return (
      <div>
        <h1 style={titleStyle}>Built for intelligent call centre</h1>
        <p style={titleStyle}>
          Empower traditional call centre with AI and greater efficiency
        </p>
      </div>
    );
  }
}

export default BIBig;
