import * as sdk from "microsoft-cognitiveservices-speech-sdk";

const speechToText = async (language, url, key) => {
    const speechConfig = sdk.SpeechConfig.fromHost(new URL(url), key);
    if (language === "Eng") {
        speechConfig.speechRecognitionLanguage = "en-US";
    }
    if (language === "Man") {
        speechConfig.speechRecognitionLanguage = "zh-CN";
    }
    const audioConfig = sdk.AudioConfig.fromDefaultMicrophoneInput();
    const recognizer = new sdk.SpeechRecognizer(speechConfig, audioConfig);
    // Speech sdk currently doesn't return promise, so we convert the callback into promise
    const recognizeSpeechPromise = new Promise((resolve, reject) => {
        recognizer.recognizeOnceAsync(
            (result) => {
                recognizer.close();
                if (result.text === undefined) {
                    reject("error in speech recognition");
                }
                resolve(result.text);
            },
            (err) => {
                recognizer.close();
                console.error(err);
            }
        );
    });
    return recognizeSpeechPromise;
};

export { speechToText };
