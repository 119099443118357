import axios from "axios";

const setHangup4 = async (
  backendUrl,
  language,
  setConnection,
  setQuoteLeft,
  addSentence
) => {
  let text;
  if (language === "Eng") {
    text = `"Thank you. Your question has been recorded. An account manager will answer your question later. Wish you a wonderful day and goodbye."`;
  } else if (language === "Man") {
    text = `"谢谢您。您的问题已经被记录。我们的客户经理之后会回答您的问题。祝您生活愉快。再见。"`;
  }

  const res = await axios
    .post(
      `${backendUrl}/getAudioUrl`,
      {
        timestamp: Date.now(),
        language: language,
        text: text,
      },
      {
        timeout: 100000,
      }
    )
    .catch((err) => {
      console.error(err);
      alert(
        "Network error. Please check internet connection and refresh page to restart demo."
      );
    });

  if (res != null && res.data != null) {
    try {
      const audio = new Audio(backendUrl + res.data);
      audio.play();
      audio.onplay = () => {
        setConnection("ttsConnection");
        setQuoteLeft(text);
        addSentence(`Robot: ${text}`);
      };
    } catch (error) {
      console.log("Error playing audio: " + error);
    }
  }
};

export { setHangup4 };
