import React, { Component } from "react";
import { ButtonBox, Button } from "./ButtonStyles";

class Scenario1Btn extends Component {
  startStage2 = (event) => {
    this.props.setDemoStage(2);
    this.props.setDemoScenario("Verify credit account information: ");
  };

  render() {
    return (
      <ButtonBox>
        <Button onClick={this.startStage2}>
          Verify credit account information
        </Button>
      </ButtonBox>
    );
  }
}

export default Scenario1Btn;
