import React, { Component } from "react";
import styled from "styled-components";
import InteractionComponent from "./InteractionComponent";

const IntentRecognition = styled.div`
  margin-top: 20px;
  width: 45%;
  display: inline-block;
`;

const CodeBox = styled.div`
  background-color: #f4f4f4;
  height: 400px;
  width: 100%;
`;

const Dialogue = styled.div`
  margin-top: 20px;
  width: 45%;
  display: inline-block;
  float: right;
`;

const DialogueBox = styled.div`
  background-color: #defeff;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
  height: 360px;
`;

const LuisRes = styled.code`
  color: #de3333;
`;

class Stage2Big extends Component {
  constructor(props) {
    super(props);
    let quoteLeft;
    if (props.language === "Eng") {
      if (props.scenario === "Verify credit account information: ") {
        quoteLeft =
          '"Good morning. This is Grace from Standard Chartered Bank Credit Card Department. Is this Mr. Tony Stark?"';
      }
      if (props.scenario === "Remind credit card payment: ") {
        quoteLeft =
          '"Good morning. This is Grace from Standard Chartered Bank Credit Card Department. Is this Mr. Steve Rogers?"';
      }
      if (props.scenario === "Play terms and conditions: ") {
        quoteLeft =
          '"Good morning. This is Grace from Standard Chartered Bank Credit Card Department. Is this Mr. Peter Parker?"';
      }
    }

    if (props.language === "Man") {
      if (props.scenario === "Verify credit account information: ") {
        quoteLeft =
          '"您好！我是渣打银行信用卡部客户专员小南，请问您是张小平先生吗？"';
      }
      if (props.scenario === "Remind credit card payment: ") {
        quoteLeft =
          '"您好！我是渣打银行信用卡部客户专员小南，请问您是王小鹏先生吗？"';
      }
      if (props.scenario === "Play terms and conditions: ") {
        quoteLeft =
          '"您好！我是渣打银行信用卡部客户专员小南，请问您是李小亮先生吗？"';
      }
    }

    this.state = {
      connection: "ttsConnection",
      quoteLeft: quoteLeft,
      quoteRight: null,
      luisRes: null,
      question: 0,
      topIntent: null,
      dialogue: [],
    };

    this.setConnection = this.setConnection.bind(this);
    this.setQuoteLeft = this.setQuoteLeft.bind(this);
    this.setQuoteRight = this.setQuoteRight.bind(this);
    this.setLuisRes = this.setLuisRes.bind(this);
    this.setQuestion = this.setQuestion.bind(this);
    this.setTopIntent = this.setTopIntent.bind(this);
    this.addSentence = this.addSentence.bind(this);
  }

  setConnection(connection) {
    this.setState({
      connection: connection,
    });
  }

  setQuoteLeft(quote) {
    this.setState({
      quoteLeft: quote,
    });
  }

  setQuoteRight(quote) {
    this.setState({
      quoteRight: quote,
    });
  }

  setLuisRes(response) {
    this.setState({
      luisRes: response,
    });
  }

  setQuestion(number) {
    this.setState({
      question: number,
    });
  }

  setTopIntent(intent) {
    this.setState({
      topIntent: intent,
    });
  }

  addSentence(sentence) {
    this.setState((prevState) => ({
      dialogue: [...prevState.dialogue, sentence],
    }));
  }

  render() {
    let luisRes;

    if (this.state.luisRes === null) {
      luisRes = <span />;
    } else {
      luisRes = (
        <pre>
          <LuisRes>{this.state.luisRes}</LuisRes>
        </pre>
      );
    }

    return (
      <div>
        <h2>Scenario - {this.props.scenario} </h2>
        <InteractionComponent
          backendUrl={this.props.backendUrl}
          luisEngUrl={this.props.luisEngUrl}
          luisManUrl={this.props.luisManUrl}
          luisKey={this.props.luisKey}
          sttUrl={this.props.sttUrl}
          sttKey={this.props.sttKey}
          language={this.props.language}
          scenario={this.props.scenario}
          quoteLeft={this.state.quoteLeft}
          quoteRight={this.state.quoteRight}
          setConnection={this.setConnection}
          setLuisRes={this.setLuisRes}
          setTopIntent={this.setTopIntent}
          setQuestion={this.setQuestion}
          setQuoteLeft={this.setQuoteLeft}
          setQuoteRight={this.setQuoteRight}
          addSentence={this.addSentence}
          connection={this.state.connection}
          question={this.state.question}
        />
        <IntentRecognition>
          <h3>Intent recognition: </h3>
          <CodeBox>{luisRes}</CodeBox>
        </IntentRecognition>
        <Dialogue>
          <h3>Dialogue: </h3>
          <DialogueBox>
            {this.state.dialogue.map((item, index) => (
              <p
                style={{ marginBlockStart: "unset", marginBlockEnd: "0.5em" }}
                key={index}
              >
                {item}
              </p>
            ))}
          </DialogueBox>
        </Dialogue>
      </div>
    );
  }
}

export default Stage2Big;
