import React, { Component } from "react";
import StartDemoEngBtn from "./Buttons/StartDemoEngBtn";
import Stage1Big from "./Stages/Stage1Big";
import Stage2Big from "./Stages/Stage2Big";
import StartDemoManBtn from "./Buttons/StartDemoManBtn";

class DemoBig extends Component {
  constructor(props) {
    super(props);

    this.state = {
      demoStage: 0,
      language: null,
      demoScenario: null,
    };

    this.setDemoStage = this.setDemoStage.bind(this);
    this.setLanguage = this.setLanguage.bind(this);
    this.setDemoScenario = this.setDemoScenario.bind(this);
  }

  setDemoStage(stage) {
    this.setState({
      demoStage: stage,
    });
  }

  setLanguage(language) {
    this.setState({
      language: language,
    });
  }

  setDemoScenario(scenario) {
    this.setState({
      demoScenario: scenario,
    });
  }

  render() {
    if (this.state.demoStage === 0) {
      return (
        <div>
          <StartDemoEngBtn
            setDemoStage={this.setDemoStage}
            setLanguage={this.setLanguage}
          />
          <StartDemoManBtn
            setDemoStage={this.setDemoStage}
            setLanguage={this.setLanguage}
          />
        </div>
      );
    }
    if (this.state.demoStage === 1) {
      return (
        <Stage1Big
          language={this.state.language}
          setDemoStage={this.setDemoStage}
          setDemoScenario={this.setDemoScenario}
        />
      );
    }
    if (this.state.demoStage === 2) {
      return (
        <Stage2Big
          backendUrl={this.props.backendUrl}
          luisEngUrl={this.props.luisEngUrl}
          luisManUrl={this.props.luisManUrl}
          luisKey={this.props.luisKey}
          sttUrl={this.props.sttUrl}
          sttKey={this.props.sttKey}
          language={this.state.language}
          scenario={this.state.demoScenario}
        />
      );
    }
  }
}

export default DemoBig;
