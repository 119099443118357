import React, { Component } from "react";
import { ButtonBox, Button } from "../Buttons/ButtonStyles";
import { getLuisRes } from "../Utilities/getLuisRes";
import { setHangup3 } from "../Utilities/setHangup3";

class ConfirmTimeMan extends Component {
  setQuote = async (event) => {
    this.props.setQuoteRight(this.props.text);
    this.props.setHideMultipleChoice(true);
    this.props.setConnection("srConnection");
    this.props.addSentence(`Customer: "${this.props.text}"`);
    const luisRes = await getLuisRes(
      this.props.luisUrl,
      this.props.luisKey,
      this.props.text,
      this.props.setLuisRes,
      this.props.setTopIntent
    );
    const qNum = this.props.question;
    if (luisRes.topIntent === "ConfirmTime" && qNum === 1.1) {
      setHangup3(
        this.props.backendUrl,
        this.props.language,
        this.props.setConnection,
        this.props.setQuoteLeft,
        this.props.addSentence,
        luisRes.entities[0]
      );
    }
  };

  render() {
    return (
      <ButtonBox>
        <Button onClick={this.setQuote}>"{this.props.text}"</Button>
      </ButtonBox>
    );
  }
}

export default ConfirmTimeMan;
