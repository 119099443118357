import React, { Component } from "react";
import { ButtonBox, Button } from "./ButtonStyles";

class Scenario2Btn extends Component {
  startStage2 = (event) => {
    this.props.setDemoStage(2);
    this.props.setDemoScenario("Remind credit card payment: ");
  };

  render() {
    return (
      <ButtonBox>
        <Button onClick={this.startStage2}>Remind credit card payment</Button>
      </ButtonBox>
    );
  }
}

export default Scenario2Btn;
