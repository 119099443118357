import axios from "axios";

const getAudioUrl = async (backendUrl, language, text) => {
  const res = await axios
    .post(`${backendUrl}/getAudioUrl`, {
      timestamp: Date.now(),
      language: language,
      text: text,
    })
    .catch((err) => {
      console.error(err);
    });
  if (res != null && res.data != null) {
    return res.data;
  } else {
    console.error("Error with getAudioUrl post request");
  }
};

export { getAudioUrl };
