import React, { Component } from "react";
import styled from "styled-components";
import anime from "animejs/lib/anime.es";
import MakeCallBtn from "../Buttons/MakeCallBtn";
import MultipleChoices1 from "../SpeechBox/MultipleChoices1";
import MultipleChoices2 from "../SpeechBox/MultipleChoices2";
import MultipleChoices3 from "../SpeechBox/MultipleChoices3";
import MultipleChoices4 from "../SpeechBox/MultipleChoices4";
import MultipleChoices5 from "../SpeechBox/MultipleChoices5";
import { ttsConnection } from "./TtsConnection";
import { srConnection } from "./SrConnection";

const DemoComponent = styled.ul`
  list-style: none;
  padding-left: 0px;
`;

const ImageLi = styled.li`
  padding-left: 60px;
  padding-right: 60px;
  position: relative;
`;

const ComputerImage = styled.img`
  width: 192px;
`;

const AiImage = styled.img`
  width: 192px;
`;

const PhoneImage = styled.img`
  width: 176px;
  position: relative;
  bottom: 10px;
`;

const QuoteLeft = styled.p`
  width: 350px;
  text-align: center;
  display: inline-block;
`;

const QuoteRight = styled.p`
  text-align: center;
  display: inline-block;
  position: relative;
  width: 350px;
  @media (min-width: 1300px) {
    left: 512px;
  }
  @media (min-width: 1280px) and (max-width: 1299.98px) {
    left: 482px;
  }
  @media (min-width: 1210px) and (max-width: 1279.98px) {
    left: 442px;
  }
  @media (min-width: 1110px) and (max-width: 1209.98px) {
    left: 342px;
  }
  @media (min-width: 1010px) and (max-width: 1109.98px) {
    left: 242px;
  }
  @media (min-width: 904px) and (max-width: 1009.98px) {
    left: 142px;
  }
`;

class InteractionComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideMultipleChoices1: true,
      hideMultipleChoices2: true,
      hideMultipleChoices3: true,
      hideMultipleChoices4: true,
      hideMultipleChoices5: true,
      hideMakeCallBtn: false,
    };

    this.setHideMultipleChoice1 = this.setHideMultipleChoice1.bind(this);
    this.setHideMultipleChoice2 = this.setHideMultipleChoice2.bind(this);
    this.setHideMultipleChoice3 = this.setHideMultipleChoice3.bind(this);
    this.setHideMultipleChoice4 = this.setHideMultipleChoice4.bind(this);
    this.setHideMultipleChoice5 = this.setHideMultipleChoice5.bind(this);
    this.setHideMakeCallBtn = this.setHideMakeCallBtn.bind(this);
    this.playTtsAnimation = this.playTtsAnimation.bind(this);
    this.pauseTtsAnimation = this.pauseTtsAnimation.bind(this);
    this.playSrAnimation = this.playSrAnimation.bind(this);
    this.pauseSrAnimation = this.pauseSrAnimation.bind(this);
  }

  setHideMultipleChoice1(state) {
    this.setState({
      hideMultipleChoices1: state,
    });
  }

  setHideMultipleChoice2(state) {
    this.setState({
      hideMultipleChoices2: state,
    });
  }

  setHideMultipleChoice3(state) {
    this.setState({
      hideMultipleChoices3: state,
    });
  }

  setHideMultipleChoice4(state) {
    this.setState({
      hideMultipleChoices4: state,
    });
  }

  setHideMultipleChoice5(state) {
    this.setState({
      hideMultipleChoices5: state,
    });
  }

  setHideMakeCallBtn(state) {
    this.setState({
      hideMakeCallBtn: state,
    });
  }

  componentDidMount() {
    if (this.props.connection === "ttsConnection") {
      let textWrapper = document.querySelector(".ttsConnection");
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      );
      this.ttsAnimation = anime.timeline({ loop: false }).add({
        targets: ".ttsConnection .letter",
        translateX: [40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => 500 + 10 * i,
      });
      this.pauseTtsAnimation();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.connection === "ttsConnection" &&
      this.props.connection === "srConnection"
    ) {
      let textWrapper = document.querySelector(".srConnection");
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      );
      this.srAnimation = anime.timeline({ loop: false }).add({
        targets: ".srConnection .letter",
        scaleX: [0, 1],
        opacity: [0, 1],
        easing: "easeInOutExpo",
        duration: 2000,
      });
    }

    if (
      prevProps.connection === "srConnection" &&
      this.props.connection === "ttsConnection"
    ) {
      let textWrapper = document.querySelector(".ttsConnection");
      textWrapper.innerHTML = textWrapper.textContent.replace(
        /\S/g,
        "<span class='letter'>$&</span>"
      );
      this.ttsAnimation = anime.timeline({ loop: false }).add({
        targets: ".ttsConnection .letter",
        translateX: [40, 0],
        translateZ: 0,
        opacity: [0, 1],
        easing: "easeOutExpo",
        duration: 1200,
        delay: (el, i) => 500 + 10 * i,
      });
    }

    if (prevProps.question === 0 && this.props.question === 1) {
      this.setState({ hideMultipleChoices2: false });
    }

    if (prevProps.question === 1 && this.props.question === 1.1) {
      this.setState({ hideMultipleChoices3: false });
    }

    if (prevProps.question === 1 && this.props.question === 2) {
      this.setState({ hideMultipleChoices4: false });
    }

    if (prevProps.question === 2 && this.props.question === 3) {
      this.setState({ hideMultipleChoices5: false });
    }
  }

  playTtsAnimation() {
    this.ttsAnimation.play();
  }

  pauseTtsAnimation() {
    this.ttsAnimation.pause();
  }

  playSrAnimation() {
    this.srAnimation.play();
  }

  pauseSrAnimation() {
    this.srAnimation.pause();
  }

  render() {
    let device;
    let connection;
    let quoteRight;
    let makeCallBtn;
    let multipleChoices;

    if (this.props.connection === "ttsConnection") {
      connection = ttsConnection;
      if (this.props.question === 0) {
        device = <ComputerImage src={"./desktop-monitor-smiley-1.png"} />;
      } else {
        device = <AiImage src={"./settings-human.png"} />;
      }
    }

    if (this.props.connection === "srConnection") {
      connection = srConnection;
      device = <AiImage src={"./settings-human.png"} />;
    }

    if (this.props.quoteRight === null) {
      quoteRight = <span />;
    } else {
      quoteRight = <QuoteRight>"{this.props.quoteRight}"</QuoteRight>;
    }

    if (this.state.hideMakeCallBtn === true) {
      makeCallBtn = <span />;
    } else {
      makeCallBtn = (
        <MakeCallBtn
          backendUrl={this.props.backendUrl}
          language={this.props.language}
          text={this.props.quoteLeft}
          setHideMultipleChoice1={this.setHideMultipleChoice1}
          setHideMakeCallBtn={this.setHideMakeCallBtn}
          playTtsAnimation={this.playTtsAnimation}
          addSentence={this.props.addSentence}
        />
      );
    }

    if (this.state.hideMultipleChoices1 === true) {
      multipleChoices = <span />;
    } else {
      multipleChoices = (
        <MultipleChoices1
          backendUrl={this.props.backendUrl}
          luisEngUrl={this.props.luisEngUrl}
          luisManUrl={this.props.luisManUrl}
          luisKey={this.props.luisKey}
          sttUrl={this.props.sttUrl}
          sttKey={this.props.sttKey}
          language={this.props.language}
          scenario={this.props.scenario}
          setConnection={this.props.setConnection}
          setLuisRes={this.props.setLuisRes}
          setTopIntent={this.props.setTopIntent}
          setQuestion={this.props.setQuestion}
          setQuoteLeft={this.props.setQuoteLeft}
          setQuoteRight={this.props.setQuoteRight}
          addSentence={this.props.addSentence}
          setHideMultipleChoice1={this.setHideMultipleChoice1}
          question={this.props.question}
        />
      );
    }

    if (this.state.hideMultipleChoices2 === false) {
      multipleChoices = (
        <MultipleChoices2
          backendUrl={this.props.backendUrl}
          luisEngUrl={this.props.luisEngUrl}
          luisManUrl={this.props.luisManUrl}
          luisKey={this.props.luisKey}
          sttUrl={this.props.sttUrl}
          sttKey={this.props.sttKey}
          language={this.props.language}
          scenario={this.props.scenario}
          setConnection={this.props.setConnection}
          setLuisRes={this.props.setLuisRes}
          setTopIntent={this.props.setTopIntent}
          setQuestion={this.props.setQuestion}
          setQuoteLeft={this.props.setQuoteLeft}
          setQuoteRight={this.props.setQuoteRight}
          addSentence={this.props.addSentence}
          setHideMultipleChoice2={this.setHideMultipleChoice2}
          question={this.props.question}
        />
      );
    }

    if (this.state.hideMultipleChoices3 === false) {
      multipleChoices = (
        <MultipleChoices3
          backendUrl={this.props.backendUrl}
          luisEngUrl={this.props.luisEngUrl}
          luisManUrl={this.props.luisManUrl}
          luisKey={this.props.luisKey}
          sttUrl={this.props.sttUrl}
          sttKey={this.props.sttKey}
          language={this.props.language}
          scenario={this.props.scenario}
          setConnection={this.props.setConnection}
          setLuisRes={this.props.setLuisRes}
          setTopIntent={this.props.setTopIntent}
          setQuestion={this.props.setQuestion}
          setQuoteLeft={this.props.setQuoteLeft}
          setQuoteRight={this.props.setQuoteRight}
          addSentence={this.props.addSentence}
          setHideMultipleChoice3={this.setHideMultipleChoice3}
          question={this.props.question}
        />
      );
    }

    if (this.state.hideMultipleChoices4 === false) {
      multipleChoices = (
        <MultipleChoices4
          backendUrl={this.props.backendUrl}
          luisEngUrl={this.props.luisEngUrl}
          luisManUrl={this.props.luisManUrl}
          luisKey={this.props.luisKey}
          sttUrl={this.props.sttUrl}
          sttKey={this.props.sttKey}
          language={this.props.language}
          scenario={this.props.scenario}
          setConnection={this.props.setConnection}
          setLuisRes={this.props.setLuisRes}
          setTopIntent={this.props.setTopIntent}
          setQuestion={this.props.setQuestion}
          setQuoteLeft={this.props.setQuoteLeft}
          setQuoteRight={this.props.setQuoteRight}
          addSentence={this.props.addSentence}
          setHideMultipleChoice4={this.setHideMultipleChoice4}
          question={this.props.question}
        />
      );
    }

    if (this.state.hideMultipleChoices5 === false) {
      multipleChoices = (
        <MultipleChoices5
          backendUrl={this.props.backendUrl}
          luisEngUrl={this.props.luisEngUrl}
          luisManUrl={this.props.luisManUrl}
          luisKey={this.props.luisKey}
          sttUrl={this.props.sttUrl}
          sttKey={this.props.sttKey}
          language={this.props.language}
          scenario={this.props.scenario}
          setConnection={this.props.setConnection}
          setLuisRes={this.props.setLuisRes}
          setTopIntent={this.props.setTopIntent}
          setQuestion={this.props.setQuestion}
          setQuoteLeft={this.props.setQuoteLeft}
          setQuoteRight={this.props.setQuoteRight}
          addSentence={this.props.addSentence}
          setHideMultipleChoice5={this.setHideMultipleChoice5}
          question={this.props.question}
        />
      );
    }

    return (
      <DemoComponent>
        <ImageLi>
          {device}
          {connection}
          <PhoneImage src={"./phone-actions-ring.png"} />
        </ImageLi>
        <li>
          <QuoteLeft>{this.props.quoteLeft}</QuoteLeft>
          {quoteRight}
        </li>
        <li style={{ height: "150px" }}>
          {makeCallBtn}
          {multipleChoices}
        </li>
      </DemoComponent>
    );
  }
}

export default InteractionComponent;
