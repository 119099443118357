import React, { Component } from "react";
import MediaQuery from "react-responsive";
import styled from "styled-components";
import BigScreen from "./Screens/BigScreen";

const BigScreenDiv = styled.div`
  max-width: 1192px;
  margin-right: auto;
  margin-left: auto;
`;

const LaptopScreenDiv = styled.div`
  margin-top: 0px;
  margin-right: 64px;
  margin-bottom: 0px;
  margin-left: 64px;
`;

const MediumScreenDiv = styled.div`
  margin-top: 0px;
  margin-right: 48px;
  margin-bottom: 0px;
  margin-left: 48px;
`;

const SmallScreenDiv = styled.div`
  margin-top: 0px;
  margin-right: 24px;
  margin-bottom: 0px;
  margin-left: 24px;
`;

const MobileScreenDiv = styled.div`
  margin-top: 0px;
  margin-right: 16px;
  margin-bottom: 0px;
  margin-left: 16px;
`;

class App extends Component {
  constructor(props) {
    super(props);

    // this.backendUrl = "http://localhost:8080";
    this.backendUrl = "https://www.xyici.com/backend";
    // this.backendUrl = "https://call-centre-nodejs.chinacloudsites.cn";
    this.luisEngUrl =
      "https://chinaeast2.api.cognitive.azure.cn/luis/v2.0/apps/9faea773-a2ad-4f46-a727-937a89808cc3";
    this.luisManUrl =
      "https://chinaeast2.api.cognitive.azure.cn/luis/v2.0/apps/b06a432a-e64e-45bf-b393-446c6352bbcf";
    this.luisKey = "25fc758b66704ed2a46de46cee58bf90";
    this.sttUrl = "wss://chinaeast2.stt.speech.azure.cn/";
    this.sttKey = "ef6268ea0ab64860bb02c351e3be59da";
  }

  render() {
    return (
      <div>
        <MediaQuery minWidth={1300}>
          <BigScreenDiv>
            <BigScreen
              backendUrl={this.backendUrl}
              luisEngUrl={this.luisEngUrl}
              luisManUrl={this.luisManUrl}
              luisKey={this.luisKey}
              sttUrl={this.sttUrl}
              sttKey={this.sttKey}
            />
          </BigScreenDiv>
        </MediaQuery>
        <MediaQuery minWidth={904} maxWidth={1299.98}>
          <LaptopScreenDiv>
            <BigScreen
              backendUrl={this.backendUrl}
              luisEngUrl={this.luisEngUrl}
              luisManUrl={this.luisManUrl}
              luisKey={this.luisKey}
              sttUrl={this.sttUrl}
              sttKey={this.sttKey}
            />
          </LaptopScreenDiv>
        </MediaQuery>
        <MediaQuery minWidth={728} maxWidth={903.98}>
          <MediumScreenDiv>
            <h1>Test</h1>
          </MediumScreenDiv>
        </MediaQuery>
        <MediaQuery minWidth={552} maxWidth={727.98}>
          <SmallScreenDiv>
            <h1>Test</h1>
          </SmallScreenDiv>
        </MediaQuery>
        <MediaQuery maxWidth={551.98}>
          <MobileScreenDiv>
            <h1>Test</h1>
          </MobileScreenDiv>
        </MediaQuery>
      </div>
    );
  }
}

export default App;
